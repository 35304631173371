export const SET_PLAYING = "SET_PLAYING";
export const SET_TEXTURES = "SET_TEXTURES";
export const SET_DIMENSIONS = "SET_DIMENSIONS";
export const SET_REEL_DIMENSIONS = "SET_REEL_DIMENSIONS";
export const SET_SLOT_CONFIG = "SET_SLOT_CONFIG";
export const SET_CONFIG = "SET_CONFIG";
export const SET_PRIZE = "SET_PRIZE";
export const TOGGLE_WIN_ANIMATION = "TOGGLE_WIN_ANIMATION";
export const TOGGLE_SPIN = "TOGGLE_SPIN";
export const SET_SPINS_LEFT = "SET_SPINS_LEFT";
export const SET_WIN_FUNC = "SET_WIN_FUNC";
export const SET_LOST_FUNC = "SET_LOST_FUNC";

export function setSlotConfig(config:any) {
    return {
		type: SET_SLOT_CONFIG,
        payload: {
            configLoaded:true,
            reelsCount: parseInt(config.nrofreels),
            pictures: config.pictures,
            reels: config.reels
        }
	}
}

export function setConfig(game_id:string, apiUrl:string, serverUrl:string) {
    return {
		type: SET_CONFIG,
        payload: {
            game_id: game_id,
            apiUrl: apiUrl,
            serverUrl: serverUrl
        }
	}
}


export function setPlaying(value:boolean, winline:string[], hasWon:boolean) {
    return {
		type: SET_PLAYING,
        payload: {
            playing: value,
            winline: winline,
            hasWon: hasWon
        }
	}
}

export function toggleWinAnimation(value:boolean) {
    return {
		type: TOGGLE_WIN_ANIMATION,
        payload: {
            showWinAnimation: value
        }
    }
}

export function toggleSpin(value:boolean) {
    return {
		type: TOGGLE_SPIN,
        payload: {
            spinEnabled: value
        }
    }
}

export function setPrize(voucher:any) {    
    return {
       type: SET_PRIZE,
       payload: {
            voucher: voucher
       }
    }
}

export function setTextures(textures:PIXI.Texture[]) {
    return {
		type: SET_TEXTURES,
        payload: {
            textures: textures
        }
	}
}

export function setDimensions(width:number, height:number) {
    return {
        type: SET_DIMENSIONS,
        payload: {
            currentCanvasWidth: width,
            currentCanvasHeight: height
        }
	}
}

export function setReelDimensions(width:number, height:number, reels:number, rows:number, spacing:number) {
    let space = width*0.04;
    let iconWidth = (width - ((reels + 1) * space)) / reels;
    let iconHeight = (height - ((rows*1) * space)) / (rows + 2);
    
    return {
        type: SET_REEL_DIMENSIONS,
        payload: {
          iconWidth: iconWidth,
          iconHeight: iconHeight,
          iconVertSpacing: space,
          iconHorSpacing: space
        }
	}
}

export function setSpinsLeft(value:number) {
    return {
        type: SET_SPINS_LEFT,
        payload: {
            // @ts-ignore
            spinsLeft: value
        }
    }
}

export function setWinModalFunc(func:Function) {
	return {
        type: SET_WIN_FUNC,
        payload: {
            winFunc: func
        }
    }
}
export function setLostModalFunc(func:Function) {
	return {
        type: SET_LOST_FUNC,
        payload: {
            lostFunc: func
        }
    }
}

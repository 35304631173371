export const gameSounds = {
    spinButtonClick: new Audio("./assets/slotmachine/sounds/spinButton.ogg"), 
    spinStopRoll: new Audio("./assets/slotmachine/sounds/spinStopRoll.ogg"), 
    spinWin: new Audio("./assets/slotmachine/sounds/spinWin.ogg")
}

export const playSound = (audio: HTMLAudioElement) => {
	try {
		audio.volume = 1;
		audio.play().catch((e) => {
			console.log('unable to play sound');	
		});
	} catch(e) {
		console.log('sound cannot be played');
	}
}
import { SET_PLAYING, SET_TEXTURES, SET_DIMENSIONS, SET_REEL_DIMENSIONS, SET_SLOT_CONFIG, TOGGLE_WIN_ANIMATION, SET_PRIZE, TOGGLE_SPIN, SET_CONFIG, SET_SPINS_LEFT, SET_WIN_FUNC, SET_LOST_FUNC } from '../actions/slotmachine';

const initialState:any = {
    playing:false,
    textures:[],
    pictures:[],
    winline:[],
    hasWon:false,
    currentCanvasWidth: 400,
    currentCanvasHeight: 300,
    iconHorSpacing:22.5,
    iconVertSpacing: 22.5,
    rows:1, // beware, two will be added in any way to properly animate
    reelsCount:3,
    reels:[[]],
    reelWidth: 120, 
    iconWidth: 90,
    iconHeight: 90,
    configLoaded:false,
    showWinAnimation:false,
    voucher:null,
    spinEnabled:false,
    game_id:"",
    apiUrl:"",
    serverUrl:"",
    spinsLeft: 1,
	winFunc: () =>{},
	lostFunc: () =>{}
}

export default function slotmachineReducer(state = initialState, action:any) {
    let changes;
    switch (action.type) {
        case SET_PLAYING:
			changes = {
                ...state,
                playing: action.payload.playing,
                winline: action.payload.winline,
                hasWon: action.payload.hasWon
			};
            return changes;
        case TOGGLE_WIN_ANIMATION: 
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case TOGGLE_SPIN: 
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case SET_SPINS_LEFT: 
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case SET_PRIZE: 
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case SET_TEXTURES:
			changes = {
                ...state,
				textures: action.payload.textures
            };
            return changes;
        case SET_DIMENSIONS: 
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case SET_REEL_DIMENSIONS: 
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case SET_SLOT_CONFIG: 
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        case SET_CONFIG: 
            changes = {
                ...state,
                ...action.payload
            };
            return changes;  
		case SET_WIN_FUNC: 
            changes = {
                ...state,
                ...action.payload
            };
            return changes;		
		case SET_LOST_FUNC: 
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        default: return state
    }
    return state;
}
import React, { Component, ReactNode, useReducer, useRef } from 'react';
import { Container } from '@inlet/react-pixi';
import SlotReel from '../slotReel/SlotReel';
import { setPlaying, setTextures, toggleWinAnimation, toggleSpin } from '../../actions/slotmachine';
import slotmachineReducer from '../../reducers/slotmachine';
import { connect } from 'react-redux';
import * as PIXI from 'pixi.js';


interface Props {
    setPlaying?:Function,
    setTextures?:Function,
    toggleWinAnimation?:Function,
    toggleSpin?:Function,
    slotmachine?:any,
    icons:number,
    isRunning?:boolean,
    app:any,
	prizeWinModal:Function
	lostModal:Function
}

interface State{
    reels:any[]
    reelsComplete:number,
    symbols:string[]
    loadingDone:boolean,
    x:number,
    y:number,
    reelX:number
    reelY:number,
    framesRendered:number,
}

class SlotMachine extends Component<Props, State> {
    reelsComplete:number = 0;
    wasRunning:boolean = false;
    baseImgUrl:string = './assets/slotmachine/icons/icon-';
    loader:any;
    container:any = React.createRef();

    constructor(props:Props) {
        super(props);
        
        this.state = {
            reels:[],
            reelsComplete:0,
            symbols:[],
            loadingDone: false,
            x:0,
            y:0,
            reelX:0,
            reelY:0,
            framesRendered: 0
        }
    }

    componentDidUpdate() {
        if(this.props.isRunning && !this.wasRunning) {
            this.setState({
                reelsComplete: 0
            })
        }
        this.wasRunning = this.props.isRunning;
    }

    componentDidMount() {
        this.loader = new PIXI.Loader();
        let symbols = [];
        for(let pic of this.props.slotmachine.pictures) {
            let url = "." + pic.image;
            this.loader.add(url);
            symbols.push(url);
        }
        this.setState({
            symbols: symbols,
            framesRendered: 0
        });
        this.loader.load(() => { this.onAssetsLoaded() });
        this.props.app.ticker.add((delta:any)=> { // is required to show the animation render perhaps because of using both pixi and react components, or using a react component as a pixi component.
            this.setState({
                framesRendered: this.state.framesRendered + 1
            })
        })
    }

    async onAssetsLoaded() {
        let textures:any = {};
        let i = 0;
        for(let pic of this.props.slotmachine.pictures) {
            PIXI.Texture.fromURL(pic.image, {crossOrigin:true}).then(tex => {
                textures[pic.id] = tex;
                if(++i ===  this.props.slotmachine.pictures.length) {
                    this.props.setTextures(textures);
                    this.setState({
                        loadingDone: true
                    });
                    window.dispatchEvent(new CustomEvent('loadingDone'))
                }
            });
        }

    }

    onReelComplete() {
        let completed = this.state.reelsComplete + 1;
        if(completed >= this.props.slotmachine.reelsCount){
            if(this.props.slotmachine.hasWon) {
                this.props.toggleWinAnimation(true);
                this.showWinModal(); 
             
            } else if(this.props.slotmachine.spinsLeft == 0) {
                setTimeout(() => { 
                    this.showLostModal();
                }, 500);
            }            
            else {
                this.props.toggleSpin(true);
            }

            
            this.props.setPlaying(false);
			window.dispatchEvent(new CustomEvent('setSpinning', {detail:{spinning:false}}))
        }
        this.setState({
            reelsComplete: completed
        })
    }

    showWinModal() {        
        this.props.prizeWinModal(this.props.slotmachine.voucher, ()=>{this.props.toggleSpin(true);});
        this.props.toggleSpin(true);
    }

	showLostModal() {
		this.props.lostModal();
		this.props.toggleSpin(true);  
	}

    render(): ReactNode {
        return (			
            <Container ref={this.container} y= {this.state.y} width={this.props.slotmachine.currentCanvasWidth} height={this.props.slotmachine.currentCanvasHeight}>
               {this.state.loadingDone && [...Array(this.props.slotmachine.reelsCount)].map((x, i) =>
                    <SlotReel key={i} index={i} icons={this.props.icons} x={(this.props.slotmachine.reelWidth + this.props.slotmachine.iconHorSpacing )*i} y={this.props.slotmachine.iconHeight+this.props.slotmachine.iconVertSpacing} app={this.props.app} onComplete={ () => { this.onReelComplete() }} />
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state:any) => ({
	prizeWinModal: state.slotmachineReducer.winFunc,
	lostModal: state.slotmachineReducer.lostFunc,
    slotmachine:state.slotmachineReducer,
    isRunning:state.slotmachineReducer.playing
});
const mapDispatchToProps = (dispatch: Function) => {
	return {
       setPlaying: (value:boolean) => { dispatch(setPlaying(value, null, false)); },
       setTextures: (textures:PIXI.Texture[]) => { dispatch(setTextures(textures)); },
       toggleWinAnimation: (value:boolean) => { dispatch(toggleWinAnimation(value)); },
       toggleSpin: (config:any) => { dispatch(toggleSpin(config))}
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(SlotMachine)
import React, { Component, ReactNode, useReducer, useRef } from 'react';
import { connect, Provider } from 'react-redux';
import logo from './../../../logo.svg';
import { Stage, Container, Sprite, useTick, useApp, AppConsumer } from '@inlet/react-pixi';
import SlotMachine from '../slotmachine/SlotMachine';
import { setDimensions, setReelDimensions } from '../../actions/slotmachine';
import * as PIXI from 'pixi.js';
import { prices } from '../../types/PriceReel';
import MobileDetect from 'mobile-detect';
// PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR
// PIXI.settings.MIPMAP_TEXTURES = PIXI.MIPMAP_MODES.ON
// PIXI.settings.RESOLUTION = 1
PIXI.settings.ROUND_PIXELS = true
PIXI.settings.RENDER_OPTIONS.autoDensity = true;

interface Props {
    store:any,
    setDimensions:Function,
    slotmachine:any,
    setReelDimensions:Function
}

interface State{
    logoX:number,
    logoY:number,
	active:boolean,
	antialias:boolean
}

export const SCALING_STEPS = [
    {scale:0.64, min_height:440.0, fbLikeBtnClass: 'altinSmallest'},
    {scale:0.70, min_height:625.0, fbLikeBtnClass: 'altinSmall'},
    {scale:0.82, min_height:750.0, fbLikeBtnClass: 'altinMedium'},
    {scale:1, 	 min_height:920.0, fbLikeBtnClass: 'altinLarge'}
];
export const MINRATIO = 4/3;
export const MAXRATIO = 16 / 9;
export const VIEWWIDTH:number = 1920;
export const VIEWHEIGHT:number = 1080;
export const VIEWDIAG = Math.sqrt(VIEWWIDTH*VIEWWIDTH + VIEWHEIGHT*VIEWHEIGHT); //pythagoras theorem to calc diag

class Game extends Component<Props, State> {
    stageRef:any = React.createRef();
    root:any;
    rootComponent:any;
    stage:Stage;
    renderer:PIXI.Renderer;
    canvas:any;
	md = new MobileDetect(window.navigator.userAgent);

    constructor(props: Props) {
        super(props);
        
        this.state = {
            logoX: 400,
            logoY: 300,
			active: true,
			antialias: this.md ? !this.md.is('iOS') : true
        }        
    }

    componentDidMount() {
        this.root = document.getElementById('root');
        this.renderer = this.stageRef.current.app.renderer;
        this.rootComponent = this.stageRef.current.app.stage.children[0];
		if(this.md.is('iOS')) {			
			$('.modal').on('shown.bs.modal', () => {
				this.setState({active:false})
			})
			$('.modal').on('hide.bs.modal', () => {
				this.setState({active:true})
				setTimeout(() => {
					this.root = document.getElementById('root');
					this.renderer = this.stageRef.current.app.renderer;
					this.rootComponent = this.stageRef.current.app.stage.children[0];
					this.onResize();				
				}, 100);
			})
		}

		//@ts-ignore
        window.addEventListener('resize', ()=> {
            setTimeout( () => 
                this.onResize()
            , 0);     
        });
        setTimeout( () => this.onResize(), 1);
    }

    onResize() {
        let root = document.getElementById('root');
        let w = root.clientWidth;
        let h = Math.round(root.clientWidth / (MINRATIO));
        this.props.setDimensions(w, h);
        if(this.stageRef != null && this.stageRef.current != null) {
            let scale = ((w) / (400)*devicePixelRatio);
            this.updateAppScales(this.rootComponent, scale);
            this.renderer.resize(w*devicePixelRatio, h*devicePixelRatio);           
        }
    }

    updateAppScales(component:any, scale:number) {
        component.scale.x = scale;
        component.scale.y = scale;
    }

    selectScale():number
	{
		var w, h;

		var screenRatio = this.props.slotmachine.currentCanvasWidth / this.props.slotmachine.currentCanvasHeight;
		// var landscape = screenRatio > 1; // check to see if the game is shown in landscape mode
		var maxRatio = MAXRATIO;
		var minRatio = MINRATIO;

		if (screenRatio > maxRatio) {
			h = this.props.slotmachine.currentCanvasWidth;
			w = maxRatio * h;
		} else if (screenRatio < minRatio) {
			w = this.props.slotmachine.currentCanvasWidth;
			h = w / minRatio;
		} else {
			w = this.props.slotmachine.currentCanvasWidth;
			h = this.props.slotmachine.currentCanvasHeight;
        }

        var scalingStep:any = this.getScalingStep(h);

		return scalingStep.scale;
    }
    
    // Select the scaling step based on ranges of heights
	private getScalingStep(height:number):any
	{
		var preferredScalingStep = SCALING_STEPS[0];
		for (let step of SCALING_STEPS) {
			if (height >= step.min_height) preferredScalingStep = step;
			else break;
		}
		return preferredScalingStep;
    }

    render(): ReactNode {
        return (
            <div>
                {this.state.active && <Stage renderOnComponentChange={true} ref={this.stageRef} width={this.props.slotmachine.currentCanvasWidth} height={this.props.slotmachine.currentCanvasHeight} options={{ transparent:true, resolution:1, antialias:this.state.antialias}}>
                    <Provider store={this.props.store}>
                        <AppConsumer>
                            {app => <SlotMachine app={app} icons={14} ></SlotMachine> }
                        </AppConsumer>
                    </Provider>
                </Stage> }               
            </div>            
        );
    }
}

const mapStateToProps = (state:any) => ({
    slotmachine: state.slotmachineReducer
});
const mapDispatchToProps = (dispatch: Function) => {
	return {
       setDimensions: (width:number, height:number) => { dispatch(setDimensions(width, height)); },
       setReelDimensions: (width:number, height:number, reels:number, rows:number, spacing:number) => { dispatch(setReelDimensions(width, height, reels, rows, spacing)); },
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(Game)
import React, { Component, ReactNode } from 'react';
import logo from './logo.svg';
import './App.css';
import Game from './slotmachine/components/game/Game';
import { connect } from 'react-redux';
import { setPlaying, setSlotConfig, setPrize, toggleSpin, setConfig, setSpinsLeft, setWinModalFunc, setLostModalFunc } from './slotmachine/actions/slotmachine';

import { prices } from './slotmachine/types/PriceReel'
import Button from './components/button/Button';
import { event } from 'jquery';


interface Props {
    store:any,
    setPlaying:Function,
    slotmachine:any,
    setConfig:Function,
    setSlotConfig:Function,
    setPrize:Function,
    toggleSpin:Function
    setSpinsLeft:Function
	setWinFunc:Function
	setLostFunc:Function
}

interface State {
    spinsLeft:number,
	userAgent:string
	code:string,
	playerData:any,
	showErrorModal:Function,
	bonus_type_id?:number
}

export class App extends Component<Props, State> {

    constructor(props:Props) {
        super(props);
		this.state = {
			spinsLeft: 0,
			userAgent: '',
			code: '',
			playerData: {},
			showErrorModal: (error:string) =>{}
		};
    }

    componentDidMount() {
        this.getSpinsLeft();
		window.addEventListener('initGame', (data:any)=> {
            console.log('initGame', data);
            this.setState({
				spinsLeft: data.detail.spinsLeft,
				userAgent: data.detail.userAgent,
				code: data.detail.game_code,
				playerData: data.detail.playerData,
				showErrorModal: data.detail.showErrorModal
			})

			this.props.setWinFunc(data.detail.prizeWinModal);
			this.props.setLostFunc(data.detail.showLostModal);
            this.props.setSpinsLeft(this.getSpinsLeft());
			this.getConfig(() => {
				this.getSlotConfiguration()
			});
        });
        window.addEventListener('spinsLeftChanged', (data:any)=> {
            this.props.setSpinsLeft(data.detail.spinsLeft);
			this.setState({bonus_type_id:data.detail.bonus_type_id})
        });
    }

    getConfig(callback:Function) {
        fetch('./config.json', {
            headers: {
                "Content-Type": "application/json"
            }
        }).then(async (res:Response) => {
            let json = await res.json();
            this.props.setConfig(json.game_id, json.api_url, json.server_url);
            if(callback != null) callback();
            
        });
    }

    getSlotConfiguration() {
        
        fetch(this.props.slotmachine.apiUrl + "?func=slotconfig&game_id=" + this.props.slotmachine.game_id, {
            method: "get", 
        }).then(
            async (res: Response) => {
                let json = await res.json();
                if(json.success) {
                    this.props.setSlotConfig(json.config);
                    this.props.toggleSpin(true)
                }
            }
        );
    }

    public startPlaying() {
		let url = this.props.slotmachine.apiUrl + "?func=spin&game_id=" + this.props.slotmachine.game_id + "&code=" + this.state.code + "&email="+ this.state.playerData.email + "&agent=" + this.state.userAgent + "&track_token=" + this.state.playerData.trackToken;
		if(this.state.bonus_type_id) url += `&bonus_type_id=${this.state.bonus_type_id}`;
        if(this.state.playerData.id) url += `&player_id=${this.state.playerData.id}`;
		
        fetch(url, {
            method: "GET"
        }).then(
            async (res: Response) => {
                let json = await res.json();
                if(json.success) {
                    let winline = [json.winline.slot_picture_id1,json.winline.slot_picture_id2,json.winline.slot_picture_id3];
                    this.props.setPlaying(true, winline, json.prize != null);
					window.dispatchEvent(new CustomEvent('setSpinning', {detail:{spinning:true}}))
                    this.props.toggleSpin(false);
                    this.props.setPrize(json.theprize);
                    
                    this.props.setSpinsLeft(json.spinsleft);
                } else {
                    this.state.showErrorModal(json.result);
                }
            }
        );
    }

    getSpinsLeft() {        
        return this.state.spinsLeft ? this.state.spinsLeft : 0;
    }
    
    render():ReactNode {
        return (
            <div className="App">
                <div className="frame"></div>
                { this.props.slotmachine.configLoaded && <Game store={this.props.store}/>}
			<div className="slot-button-wrapper">
                <Button onClick={() => this.startPlaying()}></Button>
			</div>	
          </div>
        );
    }
}

const mapStateToProps = (state:any) => ({
    slotmachine: state.slotmachineReducer
});
const mapDispatchToProps = (dispatch: Function) => {
	return {
       setPlaying: (value:boolean, winline:string[], hasWon:boolean) => { dispatch(setPlaying(value, winline, hasWon)); },
       setPrize: (voucher:any) => { dispatch(setPrize(voucher)); },
       setSlotConfig: (config:any) => { dispatch(setSlotConfig(config))},
       setConfig: (game_id:string, apiUrl:string, serverUrl:string) => { dispatch(setConfig(game_id, apiUrl, serverUrl))},
       toggleSpin: (config:any) => { dispatch(toggleSpin(config))},
       setSpinsLeft: (value:number) => dispatch(setSpinsLeft(value)),
       setWinFunc: (func:Function) => dispatch(setWinModalFunc(func)),
       setLostFunc: (func:Function) => dispatch(setLostModalFunc(func))
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(App);

import React from 'react';
import { connect } from 'react-redux';
import {gameSounds, playSound} from '../../helpers/GameSounds'

interface Props {
    onClick?:Function,
    slotmachine:any
}

function Button(props:Props) {
    const clickButton = (e:any) => {
        playSound(gameSounds.spinButtonClick);
        if(props.onClick) props.onClick(e);
    } 

    const getButtonClass = () => {

        if(props.slotmachine && !props.slotmachine.spinEnabled) return "playbutton disabled";
        return "playbutton";
    }

    return (
        <button disabled={!props.slotmachine.spinEnabled} className={getButtonClass()} onClick={ (e) => clickButton(e) }><img src="./images/play-button-spin.png" alt="Play"/><span id="spins-left">{props.slotmachine.spinsLeft}</span></button>
    )
}

const mapStateToProps = (state: any) => ({
    slotmachine: state.slotmachineReducer
});

export default connect(mapStateToProps)(Button)



import React, { Component, ReactNode, useEffect } from "react";
import { Container, Sprite } from '@inlet/react-pixi';
import { connect } from "react-redux";
import * as PIXI from 'pixi.js';
import { getReel } from '../../types/PriceReel';

import {gameSounds, playSound} from '../../../helpers/GameSounds'


interface Props {
    index:number,
    icons:number,
    x:number,
    y:number,
    isRunning: boolean,
    app:any,
    onComplete:Function,
    slotmachine:any
    height:number,
    reel:any[];
}

interface State{
    reelComplete:boolean,
    container:any,
    reel:any[]
}

class SlotReel extends Component<Props, State> {
    reelContainer:any = React.createRef();
    baseImgUrl:string = './assets/slotmachine/';
    mask:any;
    frames:number = 0;
    static defaultProps:Props = {
        index: 0,
        icons: 14,
        x: 100,
        y: 100,
        isRunning: false,
        app:null,
        onComplete: ()=> {},
        slotmachine:null,
        height: (150 + 30) * 3,
        reel: []
    }
    iconsGenerated:boolean = false;
    wasRunning:boolean = false;
    reelComplete:boolean = false;
    
    tweening:any[] = [];
    animObj:any = {};
    icons:any[] = []
    
    
    constructor(props:Props) {
        super(props);
        this.state = {
            reelComplete:false,
            container: null,
            reel: this.props.slotmachine.reels[this.props.index]          
        }
    }
    
    componentDidMount() {
        this.generateRandomIcons();
        let blur = new PIXI.filters.BlurFilter();
        blur.blurX = 0;
        blur.blurY = 0;
        this.animObj = {
            position:0,
            previousPosition:0,
            blur:blur,
            container:this.reelContainer.current            
        }

        if(this.reelContainer.current != null && this.reelContainer.current.filters == null) {
            this.reelContainer.current.filters = [blur];
        }

        // this.mask = PIXI.Sprite.from(this.baseImgUrl + 'spinner_mask.png', {});
        // this.mask.width = this.props.slotmachine.reelWidth + this.props.slotmachine.iconHorSpacing;
        // this.mask.height = this.props.slotmachine.iconHeight * (this.props.slotmachine.rows + 2.5);
        // this.reelContainer.current.addChild(this.mask);
        // this.reelContainer.current.mask = this.mask;

        this.props.app.ticker.add((delta:number) => {
            if(this.reelContainer.current != null && this.reelContainer.current.filters == null) {
                this.reelContainer.current.filters = [this.animObj.blur];
            }

            this.animObj.blur.blurY = (this.animObj.position - this.animObj.previousPosition) * 10
            this.animObj.previousPosition = this.animObj.position;

            var now = Date.now();
            var remove:any[] = [];

           
            for (var i = 0; i < this.tweening.length; i++) {
                var t = this.tweening[i];
                var phase = Math.min(1, (now - t.start) / t.time);
                    t.object[t.property] = this.lerp(t.propertyBeginValue, t.target, t.easing(phase));
                    if (t.change) t.change(t);
                    if(phase > 0.975 && !t.soundPlayed) {
                        t.soundPlayed = true;
                        playSound(gameSounds.spinStopRoll);
                    }
                    if (phase === 1) {
                        t.object[t.property] = t.target;
                        // this.animObj.blur.blurY = (this.animObj.position - this.animObj.previousPosition) * 4
                        if (t.complete) t.complete(t);
                        remove.push(t);
                    }
                }


            for (var j = 0; j < this.icons.length; j++) {
                var prevy = this.icons[j].y;

                this.icons[j].y = ((this.animObj.position + j) % this.icons.length) * (this.props.slotmachine.iconHeight+this.props.slotmachine.iconVertSpacing) - (this.props.slotmachine.iconHeight+this.props.slotmachine.iconVertSpacing);
                let reelIndex = Math.round((this.animObj.position)+1) % this.state.reel.length;
                if (this.icons[j].y < 0 && prevy > (this.props.slotmachine.iconHeight+this.props.slotmachine.iconVertSpacing)) {
                    // Detect going over and swap a texture.
                    // This should in proper product be determined from some logical reel.
                    this.icons[j].src = this.props.slotmachine.textures[this.state.reel[reelIndex].slot_picture_id];
                    // s.scale.x = s.scale.y = Math.min(this.props.slotmachine.iconHeight / s.texture.width, this.props.slotmachine.iconHeight / s.texture.height)
                }
            }
            
            for (var i = 0; i < remove.length; i++) { 
                this.tweening.splice(this.tweening.indexOf(remove[i])); //TODO: this isn't correct, all tweens should be removed. using this for now since it doesn't work properly with only one tween in the list.
            }           
                
        })
    }

    getSymbol() {

    }


    
    componentDidUpdate() {
        if(this.mask != null) {
            this.mask.width = this.props.slotmachine.reelWidth + this.props.slotmachine.iconHorSpacing;
            this.mask.height = this.props.slotmachine.iconHeight * (this.props.slotmachine.rows + 2.5);
        }

        if(this.props.isRunning && !this.wasRunning) {
            this.wasRunning = this.props.isRunning;
            setTimeout(() => this.startPlay(), 100);
        } else {
            this.wasRunning = this.props.isRunning;
        }
    }

    // Basic lerp funtion.
    lerp(a1:number, a2:number, t:number) {
        return a1 * (1 - t) + a2 * t;
    }

    tweenTo(object:any, property:any, target:any, time:any, easing:any, onchange:any, oncomplete:any) {
        let tween = {
            object: object,
            property: property,
            propertyBeginValue: object[property],
            target: target,
            easing: easing,
            time: time,
            change: onchange,
            complete: oncomplete,
            start: Date.now(),
            soundPlayed: false
        };
        this.tweening.push(tween)
        return tween;
    }

    generateRandomIcons() { 
        let icons:any[] = [];
        for(let i = 0; i < this.props.slotmachine.rows+2; i++) {
            let price = this.state.reel[i % this.state.reel.length];
            icons.push({
                price: price.slot_picture_id,
                src: this.props.slotmachine.textures[price.slot_picture_id],
                x: Math.round(this.props.slotmachine.reelWidth - this.props.slotmachine.iconWidth) / 2,
                y: i * (this.props.slotmachine.iconHeight+this.props.slotmachine.iconVertSpacing) - (this.props.slotmachine.iconHeight+this.props.slotmachine.iconVertSpacing)
            });
        }
       
        this.setState({
            reelComplete: false,
            container: this.reelContainer.current,
        });
        this.icons = icons;
        this.iconsGenerated = true;
    }

    determineExtra() {        
        let startIndex = this.animObj.position % this.state.reel.length;
        let row =  Math.ceil((this.props.slotmachine.rows + 2) / 2);
        let endIcon = this.props.slotmachine.winline[this.props.index];
        let endIndex = 0;        
        for(let i = 0; i < this.state.reel.length; i++) {
            if(this.state.reel[i].slot_picture_id === endIcon) {
                endIndex = i;
            }
        }
        return endIndex - startIndex;
    }

    public startPlay() {
        var extra = this.determineExtra();
        this.tweenTo(this.animObj, 'position', this.animObj.position + this.state.reel.length + this.state.reel.length * 5 + extra, 2000 + this.props.index * 1200, this.backout(0.3), null, () => {
            if(this.props.onComplete != null) this.props.onComplete();
        });  
    }

    // Backout function from tweenjs.
    // https://github.com/CreateJS/TweenJS/blob/master/src/tweenjs/Ease.js
    backout(amount:number) {
        return function(t:number) {
            return (--t * t * ((amount + 1) * t + amount) + 1);
        };
    }

    render():ReactNode {
        return (
            <Container ref={this.reelContainer} width={this.props.slotmachine.iconWidth} height={this.props.slotmachine.iconHeight * this.props.slotmachine.rows} x={this.props.x} y={this.props.y} >
                {this.iconsGenerated && this.icons.map((icon, i) =>                
                    <Sprite key={i} texture={icon.src} width={this.props.slotmachine.iconWidth} height={this.props.slotmachine.iconHeight} x={icon.x} y={icon.y} roundPixels={true}></Sprite>
                    )}
            </Container>
        );
    }
}


const mapStateToProps = (state:any) => ({
    slotmachine:state.slotmachineReducer,
    isRunning: state.slotmachineReducer.playing
});
const mapDispatchToProps = (dispatch: Function) => {
	return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(SlotReel)